import React from "react"
import { graphql } from "gatsby"
import { withUnpublishedPreview } from 'gatsby-source-prismic'

import ProjectTemplate from '../templates/project'

import SEO from "../components/seo"
import Grid from "../components/grid"

export const query = graphql`
  query NotFound {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const NotFoundPage = props => {
  return (
    <>
      <SEO title="404" />
      <Grid>Not Found!</Grid>
    </>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    project: ProjectTemplate
  }
})